import './App.css';
import GradientLayout from "./components/layouts/gradients/GradientLayout";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <GradientLayout gradient={'linear'}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', paddingRight: '20px', paddingLeft: '20px'}}>
            <img src="/logo-uniglobal-white.png"
                 style={{maxWidth: '600px', width: '100%'}}
                 alt="Logo Uniglobal"/>
            <div style={{maxWidth: '600px', width: '100%', textTransform: 'uppercase', fontSize: '22px'}}>PÁGINA EM CONSTRUÇÃO, DESCULPEM O INCONVENIENTE</div>
          </div>
        </GradientLayout>
      </header>
    </div>
  );
}

export default App;
